const api = (endpoint: string) =>
  `${process.env.NEXT_PUBLIC_API_GATEWAY}/${endpoint}`;

const oldApi = (endpoint: string) =>
  `${process.env.NEXT_PUBLIC_OLD_API_GATEWAY}/${endpoint}`;
const newApi = (endpoint: string) =>
  `${process.env.NEXT_PUBLIC_NEW_API_GATEWAY}/${endpoint}`;
const wizzyApi = (endpoint: string) =>
  `${process.env.NEXT_PUBLIC_WIZZY_API}/${endpoint}`;
const unbxdApi = (endpoint: string) =>
  `${process.env.NEXT_PUBLIC_UNBXD_API}/${endpoint}`;

const endpoints = {
  products: {
    category: (id: number) => newApi(`product/category/${id}`),
    filters: (id: number) => newApi(`product/category/filters/${id}`),
    product: (slug: string) => newApi(`product/${slug}`),
    related: (slug: string) => newApi(`product/${slug}/related`),
    search: () => newApi(`product/search`),
    searchFilters: () => newApi(`product/search/filters`),
    searchWizzy: () => wizzyApi(`products/search`),
    searchWizzyFilters: () => wizzyApi(`products/filter`),
    searchMoreWizzy: () => wizzyApi(`products/filter`),
    autoComplete: () => wizzyApi(`autocomplete`),
  },
  category: {
    detail: (category: string) => newApi(`category/${category}`),
    mapping: (id: number) => newApi(`category/mapping/${id}`),
  },
  page: (slug: string) => newApi(`cms/page/${slug}`),
  sencoOffer: () => oldApi(`api/offer`),
  checkPincode: (pincode: any) => newApi(`order/check-pincode/${pincode}`),
  mgOffer: () => oldApi(`api/offer/mg`),
  franchiseEnquiry: () => oldApi(`api/franchise-enquiry`),
  pbAndAtOffer: () => oldApi(`api/offer/pb-and-at`),
  countries: () => newApi("cms/countries"),
  state: (country: any) => newApi(`cms/states/${country}`),
  unbxd: () =>
    unbxdApi(
      `${process.env.NEXT_PUBLIC_UNBXD_API_KEY}/${process.env.NEXT_PUBLIC_UNBXD_SITE_NAME}/items`
    ),
  auth: {
    sendOtp: () => newApi("auth/send-signup-otp"),
    signup: () => newApi("auth/signup"),
    loginSendOtp: () => newApi("auth/send-login-otp"),
    loginVerifyOtp: () => newApi("auth/verify-login-otp"),
    user: () => newApi("auth/profile"),
    address: () => newApi("auth/address"),
    addAddress: () => newApi("auth/add-address"),
    deleteAddress: (uid: any) => newApi(`auth/delete-address/${uid}`),
    updateAddress: (uid: any) => newApi(`auth/update-address/${uid}`),
    orders: () => newApi("order/list"),
    orderDetail: (oid: any) => newApi(`order/${oid}`),
    orderStatus: () => newApi("order/status-sequence"),
    orderTimeline: (oid: any, id: any) => newApi(`order/${oid}/track/${id}`),
    profileUpdate: () => newApi("auth/update-profile"),
    billingAddressUpdate: () => newApi("auth/update-billing-address"),
    getConsentForm: () => newApi("auth/get-consent-form"),
    updateConsentForm: () => newApi("auth/update-consent-form"),
    update_pancard: () => newApi(`auth/update-pancard`),
    subscribe: () => newApi(`auth/subscribe`),
  },
  cart: {
    add: () => newApi("cart/add"),
    remove: (id: any) => newApi("cart/remove/" + id),
    refresh: () => newApi("cart/refresh"),
    items: () => newApi("cart/items"),
    applyCoupon: () => newApi("discount/easyrewardz/apply"),
    availableCoupon: () => newApi("discount/easyrewardz/list"),
    removeCoupon: () => newApi("discount/easyrewardz/remove"),
    couponVerifyOtp: () => newApi("discount/easyrewardz/verify-otp"),
    couponResendOtp: () => newApi("discount/easyrewardz/resend-otp"),
    dGSendOtp: () => newApi("discount/digi/apply"),
    dGVerifyOtp: () => newApi("discount/digi/verify-otp"),
    dGRemove: () => newApi("discount/digi/remove"),
  },
  home: {
    banner: () => newApi("cms/banners"),
    menu: () => newApi("cms/menus"),
    footerMenu: () => newApi("cms/menus/footer"),

    customerFavourites: () => newApi("product/flag/best_seller"),
    newArrival: () => newApi("product/flag/new_arrival"),
    dealOfTheDay: () => newApi("product/flag/deal_of_the_day"),
    trendingDesign: () => newApi(`product/list-by-slugs`),
    mensCollections: () => newApi(`category/mens-collections`),
    subscribe: () => api(`page/subscribe`),
    live: () => newApi(`cms/firework-live`),
    liveMobile: () => oldApi(`api/widget/live-mobile`),
  },
  blog: {
    list: () => oldApi("api/blog"),
    byCategory: (slug: string) => oldApi("api/blog/category/" + slug),
    detail: (slug: string) => oldApi("api/blog/" + slug),
  },
  solitaire: {
    filters: () => oldApi("api/solitaire/filters"),
    list: () => oldApi("api/solitaire"),
    detail: (slug: string) => oldApi("api/solitaire/" + slug),
  },
  status: (txnid: any) => newApi(`order/status/${txnid}`),
  storesData: () => newApi("cms/stores"),
  wishlist: {
    items: () => newApi(`wishlist/items`),
    add: () => newApi(`wishlist/add`),
    remove: () => newApi(`wishlist/remove`),
  },
  order: {
    checkout: () => newApi("order/checkout"),
    orders: () => newApi("order/list"),
  },
  gamize: {
    load: () => `${process.env.NEXT_PUBLIC_GAMIZE_API}/gamaiz_api.php`,
  },
  giftCard: {
    purpose: () => newApi("giftcard/purposes"),
    template: () => newApi("giftcard/giftcard-templates"),
    purchase: () => newApi("giftcard/create"),
    paymentStatus: (oid: any) => newApi(`giftcard/status/${oid}`),
    orderHistory: () => newApi(`giftcard/list`),
    checkBalance: () => newApi(`giftcard/check-balance/send-otp`),
    checkbalanceOtp: () => newApi(`giftcard/check-balance/verify-otp`),
    redeemtion: () => newApi(`discount/gift-card/apply`),
    redeemtionOtp: () => newApi(`discount/gift-card/verify-otp`),
    removeGiftcard: () => newApi(`discount/gift-card/remove`),
  },
  astro: {
    zodiacList: () => newApi("astro/zodiac"),
    astrologerList: () => newApi("astro/astrologer"),
    astrologerDetails: (slug: any) => newApi(`astro/astrologer/${slug}`),
    astroAppointmentList: () => newApi("astro/appointment/list"),
    kundaliList: () => newApi("astro/kundali/list"),

    bookAppointment: () => newApi("astro/appointment"),
    horoscope: (name: any, date: any) =>
      newApi(`astro/horoscope/${name}/${date}`),
    kundaliTypes: () => newApi("astro/kundali/types"),
    bookKundali: () => newApi("astro/kundali"),
    appointmentStatus: (oid: any) => newApi(`astro/appointment/status/${oid}`),
    kundaliStatus: (oid: any) => newApi(`astro/kundali/status/${oid}`),
    coupon: (oid: any) => newApi(`astro/appointment/${oid}/coupon-apply`),
    otp: (oid: any) => newApi(`astro/appointment/${oid}/coupon-verify-otp`),
    resend: (oid: any) => newApi(`astro/appointment/${oid}/coupon-resend-otp`),

    cancelCoupon: (oid: any) =>
      newApi(`astro/appointment/${oid}/coupon-remove`),
  },
  search: {},
  stores: {
    states: () => newApi(`cms/stores/states`),
    cities: (state: string) => newApi(`cms/stores/${state}/cities`),
    stores: (state: string, city: string) =>
      newApi(`cms/stores/${state}/${city}`),
    store: (slug: string) => newApi(`cms/stores/${slug}`),
  },
  store_appointment: {
    create: () => newApi(`store-appointment/create`),
    list: () => newApi(`store-appointment/list`),
  },
  gold: {
    create: () => newApi(`goldbooking/create`),
    status: (id: string) => newApi(`goldbooking/status/${id}`),
    list: () => newApi(`goldbooking/list`),
  },
  silver: {
    create: () => newApi(`silverbooking/create`),
    status: (id: string) => newApi(`silverbooking/status/${id}`),
    list: () => newApi(`silverbooking/list`),
  },
  feedback: {
    option: () => newApi("feedback/option-choices"),
    feedback: () => newApi("feedback/purchase"),
    feedbackOrder: () => newApi("feedback/order"),
    feedbackSummary: (slug: any) => newApi(`product/${slug}/reviews/summary`),
    feedbackSummaryList: (slug: any) => newApi(`product/${slug}/reviews`),
    reviewByCustomer: (slug: any, id: any) =>
      newApi(`product/${slug}/reviews/${id}`),
  },
  analytics: {
    track: () => newApi("analytics/track/referrer"),
  },
};

export default endpoints;
